export const portraitPhotos = [
    [
        "portraits/dolac_029.jpg",
        "portraits/IMG_5508.jpg",
        "portraits/IMG_5674.jpg",
        "portraits/drugidio_157.jpg",
    ],
    [
        "portraits/IMG_0186.jpg",
        "portraits/IMG_0255.jpg",
        "portraits/IMG_0328.jpg",
        "portraits/IMG_0248.jpg",
    ],
    [
        "portraits/IMG_2597.jpg",
        "portraits/IMG_2677.jpg",
        "portraits/IMG_2760.jpg",
        "portraits/IMG_2612.jpg",
    ],
    [
        "portraits/IMG_3920.jpg",
        "portraits/IMG_3355.jpg",
        "portraits/IMG_3447.jpg",
        "portraits/IMG_3924.jpg",
    ],
    [
        "portraits/IMG_2028.jpg",
        "portraits/IMG_7058-2.jpg",
        "portraits/IMG_6967.jpg",
        "portraits/IMG_2068.jpg",
    ],
    [
        "portraits/IMG_2999.jpg",
        "portraits/IMG_3156.jpg",
        "portraits/IMG_3017.jpg",
        "portraits/jijijiji.jpg",
    ],
    [
        "portraits/IMG_5935.jpg",
        "portraits/IMG_5626.jpg",
        "portraits/IMG_62782.jpg",
        "portraits/IMG_5963.jpg",
    ],
    [
        "portraits/IMG_0293.jpg",
        "portraits/IMG_0417.jpg",
        "portraits/IMG_0389.jpg",
        "portraits/IMG_0433.jpg",
    ],
    [
        "portraits/IMG_5969.jpg",
        "portraits/IMG_3766.jpg",
        "portraits/novo2-2.jpg",
        "portraits/IMG_5768.jpg",
    ],
    [
        "portraits/IMG_9567-2.jpg",
        "portraits/dvojka2.jpg",
        "portraits/IMG_2810.jpg",
        "portraits/IMG_9582.jpg",
    ],
    [
        "portraits/lorena2_442.jpg",
        "portraits/lorena2_3247.jpg"
    ]
];
  